import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import shape5 from "../../assets/images/shapes/shape-10.png";
import { ContactForm } from "../../components/ContactForm";

const Services = () => {
  pageTitle("Services");
  const servicesData = [
    {
      id: 1,
      title: "Automation Tool",
      description:
        " Innovative tools that automate and optimize your business processes, bridging gaps between departments.",
      icon: "icon-04",
      href: "/services-details",
      data_aos_delay: "00ms",
    },
    {
      id: 2,
      title: "Custom Software Development",
      description:
        "Tailored software solutions to meet your unique business needs and drive efficiency.",
      icon: "icon-08",
      href: "/services-details",
      data_aos_delay: "200ms",
    },
    {
      id: 3,
      title: "Mobile App Development",
      description:
        "High-performance mobile apps designed for seamless user experiences across all devices.",
      icon: "icon-12",
      href: "/services-details",
      data_aos_delay: "400ms",
    },
    {
      id: 4,
      title: "Web Development and Design",
      description:
        "Responsive and visually stunning websites that engage users and enhance your online presence.",
      icon: "icon-04",
      href: "/services-details",
      data_aos_delay: "00ms",
    },
    {
      id: 5,
      title: "Digital Marketing",
      description:
        "Data-driven digital marketing strategies that boost visibility, engagement, and conversions.",
      icon: "icon-08",
      href: "/services-details",
      data_aos_delay: "200ms",
    },
    {
      id: 6,
      title: "Automation Tools:",
      description: "Consistency in posting content fosters a sense of reliability among followers.",
      icon: "icon-12",
      href: "/services-details",
      data_aos_delay: "400ms",
    },
  ];

  const servicesDataTwo = [
    {
      id: 1,
      title: "Automation Tools",
      number: "01",
      description:
        "Streamline your business operations with automation tools that enhance efficiency and connectivity across departments.",
      icon: "icon-04",
      href: "/automation-tool",
      data_aos_delay: "00ms",
      buttontext: "Read More",
    },
    {
      id: 2,
      title: "Custom ERP Solutions",
      number: "02",
      description:
        "Optimize your business processes with bespoke ERP systems tailored to your organizational needs.",
      icon: "icon-12",
      href: "/custom-erp-solutions",
      data_aos_delay: "200ms",
      buttontext: "Read More",
    },
    {
      id: 3,
      title: "Custom Software Development",
      number: "03",
      description:
        "Develop unique software solutions that align with your business objectives and drive innovation.",
      icon: "icon-08",
      href: "/custom-software",
      data_aos_delay: "400ms",
      buttontext: "Read More",
    },
    {
      id: 4,
      title: "Digital Marketing",
      number: "04",
      description:
        "Elevate your brand with strategic digital marketing that increases online visibility and customer engagement.",
      icon: "icon-04",
      href: "/digital-marketing",
      data_aos_delay: "600ms",
      buttontext: "Read More",
    },
    {
      id: 5,
      title: "Mobile App Development",
      number: "05",
      description:
        "Create cutting-edge mobile apps that deliver exceptional user experiences on both iOS and Android platforms.",
      icon: "icon-08",
      href: "/mobile-app",
      data_aos_delay: "600ms",
      buttontext: "Read More",
    },
    {
      id: 6,
      title: "Web Development and Design",
      number: "06",
      description:
        "Build visually appealing and responsive websites that captivate users and boost your digital presence.",
      icon: "icon-12",
      href: "/web-development",
      data_aos_delay: "600ms",
      buttontext: "Read More",
    },
  ];
  return (
    <>
      <HeaderOne></HeaderOne>
      <BreadCrumb></BreadCrumb>
      <div className="service__page p_relative see__pad">
        <div className="auto-container">
          <div className="row">
            {servicesData.map((item, id) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 wow fadeInUp animated"
                data-wow-delay={item.data_aos_delay}
                data-wow-duration="1500ms"
                key={id}
              >
                <div className="service__block">
                  <div className="service__icon">
                    <i className={item.icon}></i>
                    <div className="service__icon__two"></div>
                  </div>
                  <div className="service__text">
                    <h4>
                      <Link to={item.href}>{item.title}</Link>
                    </h4>
                    <p>{item.description}</p>
                  </div>
                  <div className="service__button">
                    <Link to={item.href}>
                      <i className="icon-05"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <section className="service__two p_relative">
        <div className="row">
          {servicesDataTwo.map((item, id) => (
            <div
              className="col-xl-3 col-lg-6 col-md-12 wow fadeInUp animated"
              data-wow-delay={item.data_aos_delay}
              data-wow-duration="1500ms"
              key={id}
            >
              <div className="service__block__two p_relative">
                <div className="service__image" style={{ backgroundImage: `url(${shape5})` }}></div>
                <div className="service__icon">
                  <div className="service__number">
                    <h1>{item.number}</h1>
                  </div>
                  <i className={item.icon}></i>
                </div>
                <div className="service__text">
                  <h4>
                    <Link to={item.href}>{item.title}</Link>
                  </h4>
                  <p>{item.description}</p>
                </div>
                <div className="service__button__two">
                  <Link to={item.href}>{item.buttontext}</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <ContactForm />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default Services;
