import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeOne from "./Pages/HomeOne/HomeOne/HomeOne";
import HomeTwo from "./Pages/HomeTwo/HomeTwo/HomeTwo";
import HomeThree from "./Pages/HomeThree/HomeThree/HomeThree";
import About from "./Pages/About/About";
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import Services from "./Pages/Services/Services";
import ServicesDetails from "./Pages/Services/ServicesDetails";
import AutomationTool from "./Pages/Services/AutomationTool";
import CustomErpSolutions from "./Pages/Services/CustomErpSolutions";
import CustomSoftware from "./Pages/Services/CustomSoftware";
import DigitalMarketing from "./Pages/Services/DigitalMarketing";
import MobileAppDevelopment from "./Pages/Services/MobileAppDevelopment";
import WebDevelopmentalDesign from "./Pages/Services/WebDevelopmentalDesign";
import Projects from "./Pages/Projects/Projects";
import ProjectsDetails from "./Pages/Projects/ProjectsDetails";
import Pricing from "./Pages/Pricing/Pricing";
import Team from "./Pages/Team/Team";
import Testimonial from "./Pages/Testimonial/Testimonial";
import Error from "./Pages/Error/Error";
import Blog from "./Pages/Blog/Blog";
import BlogDetails from "./Pages/Blog/BlogDetails";
import Contact from "./Pages/Contact/Contact";
import WOW from "wowjs";
import ScrollToTop from "./Pages/ScrollTop";

function App() {
  useEffect(() => {
    new WOW.WOW({
      mobile: false,
    }).init();
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/home-two" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services-details" element={<ServicesDetails />} />
          <Route path="/automation-tool" element={<AutomationTool />} />
          <Route path="/custom-erp-solutions" element={<CustomErpSolutions />} />
          <Route path="/custom-software" element={<CustomSoftware />} />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/mobile-app" element={<MobileAppDevelopment />} />
          <Route path="/web-development" element={<WebDevelopmentalDesign />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects-details" element={<ProjectsDetails />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/error" element={<Error />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <ScrollTop />
    </>
  );
}

export default App;
