import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";
import { ContactForm } from "../../components/ContactForm";

const AutomationTool = () => {
  pageTitle("Automation Tool");

  const servicesData = [
    { id: 1, title: "Automation Tools ", slug: "/automation-tool" },
    { id: 2, title: "Custom Erp Solutions", slug: "/custom-erp-solutions" },
    { id: 3, title: "Custom Software Development", slug: "/custom-software" },
    { id: 4, title: "Mobile App Development", slug: "/mobile-app" },
    { id: 5, title: "Digital Marketing", slug: "/digital-marketing" },
    { id: 6, title: "Web Development & Design", slug: "/web-development" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li key={service.id} className={service.slug === "/automation-tool" ? "active" : ""}>
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      <BreadCrumb></BreadCrumb>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>
                  <ul className="service__details__1__cat">{renderServices()}</ul>
                </div>
                <div
                  className=" sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+447366606750</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Automation <span>Tools:</span>{" "}
                </h2>
                <p>
                  At Binary Tech Works, we believe in harnessing the power of automation to drive
                  operational efficiency and streamline workflows. Our automation tools are designed
                  to optimize business processes, reduce manual effort, and improve collaboration
                  across all departments. Whether you’re looking to automate repetitive tasks,
                  bridge gaps between teams, or implement a seamless workflow, our tools help you
                  achieve more with less effort.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Efficiency and Speed
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Seamless Integration
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Enhanced Collaboration
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Scalability
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Customizable Solutions
                      </Link>
                    </li>
                  </div>
                </div>
                <p>
                  At Binary Tech Works, we help you stay ahead of the competition by automating your
                  most critical processes. Whether you need to enhance collaboration between teams
                  or free up time for strategic projects, our automation tools empower your business
                  to work smarter, not harder. Contact us today to learn more about how we can
                  tailor our automation solutions to fit your business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <ContactForm />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default AutomationTool;
