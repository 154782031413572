import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const FooterOne = () => {
  return (
    <>
      <footer className="main__footer">
        <div className="footer-top">
          <div className="auto-container">
            <div className="top-inner">
              <div className="left__top">
                <h3>Crafting Technology Beyond Horizons</h3>
              </div>
              <div className="right__top">
                <div className="btn-box">
                  <Link to="/contact" className="theme-btn theme-btn-one">
                    <i className="icon-02"></i> Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__one">
          <div className="footer-widget-section">
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget footer-logo-widget wow fadeInUp animated"
                    data-wow-delay="00ms"
                    data-wow-duration="1500ms"
                  >
                    <figure className="footer-logo">
                      <Link to="/">
                        <img src={Logo} alt="" />
                      </Link>
                    </figure>
                    <div className="text">
                      <p>
                        Bridging imagination and technology to create solutions that go beyond
                        limits.
                      </p>
                    </div>
                    <div className="btn-box">
                      <Link to="/about" className="theme-btn theme-btn-one">
                        <i className="icon-02"></i> About Us
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column" />
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget news-letter-widget ml_80 wow fadeInUp animated"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Newletter</h4>
                    </div>
                    <div className="text">
                      <p>
                        Bridging imagination and technology to create solutions that go beyond
                        limits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                  <div
                    className="footer-widget office-widget wow fadeInUp animated"
                    data-wow-delay="400ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="widget-title">
                      <h4>Official info:</h4>
                    </div>
                    <div className="widget-content">
                      <div className="text">
                        <p>38 littleton road, Sudbury hill, Harrow, london, HA1 3SU</p>
                      </div>
                      <Link to="tell:++447366606750">+447366606750</Link> <br />
                      <Link to="mailto:info@binarytechworks.com">info@binarytechworks.com</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="auto-container">
              <div className="bottom-inner">
                <div className="copyright">
                  <p>
                    © 2024 <Link to="/">Binary Tech Works.</Link> All rights reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
