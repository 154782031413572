import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";
import { ContactForm } from "../../components/ContactForm";

const MobileAppDevelopment = () => {
  pageTitle("Mobile App Development");

  const servicesData = [
    { id: 1, title: "Automation Tools ", slug: "/automation-tool" },
    { id: 2, title: "Custom Erp Solutions", slug: "/custom-erp-solutions" },
    { id: 3, title: "Custom Software Development", slug: "/custom-software" },
    { id: 4, title: "Mobile App Development", slug: "/mobile-app" },
    { id: 5, title: "Digital Marketing", slug: "/digital-marketing" },
    { id: 6, title: "Web Development & Design", slug: "/web-development" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li key={service.id} className={service.slug === "/mobile-app" ? "active" : ""}>
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      <BreadCrumb></BreadCrumb>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>
                  <ul className="service__details__1__cat">{renderServices()}</ul>
                </div>
                <div
                  className=" sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+447366606750</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Mobile App <span> Development</span>{" "}
                </h2>
                <p>
                  In the digital age, a strong online presence is essential for any business looking
                  to succeed. At Binary Tech Works, we provide comprehensive digital marketing
                  solutions designed to boost your brand’s visibility, engage your target audience,
                  and drive measurable results. From SEO to social media marketing, we use
                  data-driven strategies to help you achieve your business goals and stand out in a
                  crowded marketplace.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Custom Solutions
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> User-Centered Design
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Cross-Platform Expertise
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Scalability and Performance
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> End-to-End Development
                      </Link>
                    </li>
                  </div>
                </div>
                <p>
                  At Binary Tech Works, we’re passionate about turning your mobile app ideas into
                  reality. Whether you’re launching a new app or improving an existing one, our team
                  of skilled developers and designers is here to help. Contact us today to discuss
                  your project and discover how our mobile app development services can elevate your
                  business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <ContactForm />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default MobileAppDevelopment;
