import { useState } from "react";

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());

    const requestBody = new FormData();
    requestBody.append("formID", "243284598444064");
    requestBody.append("q4_name", data.name);
    requestBody.append("q5_typeA5", data.email);
    requestBody.append("q6_typeA6", data.subject);
    requestBody.append("q7_typeA7", data.phone);
    requestBody.append("q8_typeA8", data.message);

    try {
      const res = await fetch("https://submit.jotform.com/submit/243284598444064/", {
        method: "POST",
        body: requestBody,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      e.target.reset();
      alert("Message sent successfully");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="default-form">
      <div className="row clearfix">
        <div className="col-xl-6 form-group">
          <input type="name" name="name" placeholder="Your Name *" required="" />
        </div>
        <div className="col-xl-6 col-lg-12 form-group">
          <input type="email" name="email" placeholder="Your Email *" required="" />
        </div>
        <div className="col-xl-6 col-lg-12 form-group">
          <input type="text" name="subject" placeholder="Your Subject " required="" />
        </div>
        <div className="col-xl-6 col-lg-12 form-group">
          <input type="text" name="phone" placeholder="Your Phone " required="" />
        </div>
        <div className="ccol-xl-6 col-lg-12 form-group">
          <textarea name="message" placeholder="Message"></textarea>
        </div>
        <div className="col-xl-12 form-group message-btn btn-box">
          <button
            className="theme-btn theme-btn-one"
            type="submit"
            name="submit-form"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Send your Message"}
          </button>
        </div>
      </div>
    </form>
  );
};
