import React from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link } from "react-router-dom";
import two from "../../assets/images/resource/man.png";
import service from "../../assets/images/resource/service-details.jpg";
import basebg from "../../assets/images/resource/base.png";
import { ContactForm } from "../../components/ContactForm";

const CustomErpSolutions = () => {
  pageTitle("Custom Erp Solutions");

  const servicesData = [
    { id: 1, title: "Automation Tools ", slug: "/automation-tool" },
    { id: 2, title: "Custom Erp Solutions", slug: "/custom-erp-solutions" },
    { id: 3, title: "Custom Software Development", slug: "/custom-software" },
    { id: 4, title: "Mobile App Development", slug: "/mobile-app" },
    { id: 5, title: "Digital Marketing", slug: "/digital-marketing" },
    { id: 6, title: "Web Development & Design", slug: "/web-development" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li key={service.id} className={service.slug === "/custom-erp-solutions" ? "active" : ""}>
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <HeaderOne></HeaderOne>
      <BreadCrumb></BreadCrumb>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>
                  <ul className="service__details__1__cat">{renderServices()}</ul>
                </div>
                <div
                  className=" sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+447366606750</h4>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Custom<span> ERP Solutions:</span>{" "}
                </h2>
                <p>
                  In today’s complex business environment, efficiency and seamless integration are
                  key to staying competitive. At Binary Tech Works, we specialize in creating Custom
                  ERP (Enterprise Resource Planning) solutions that connect all facets of your
                  business into one unified system. Our ERP solutions are designed to streamline
                  your operations, improve decision-making, and enhance productivity, giving you the
                  control you need to manage every aspect of your business efficiently.
                </p>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Tailored to Your Business
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Integrated Operations
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Scalable and Flexible
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Real-Time Insights
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Enhanced Efficiency and Productivity
                      </Link>
                    </li>
                  </div>
                </div>
                <p>
                  At Binary Tech Works, we’re committed to delivering ERP solutions that are as
                  unique as your business. Whether you’re looking to improve operational efficiency,
                  enhance decision-making, or scale your business, our Custom ERP solutions can help
                  you achieve your goals. Contact us today to discuss your needs and discover how
                  our ERP solutions can transform your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <ContactForm />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterOne></FooterOne>
    </>
  );
};

export default CustomErpSolutions;
