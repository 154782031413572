import React, { useState } from "react";
import two from "../../../assets/images/resource/man.png";
import { ContactForm } from "../../../components/ContactForm";

const ServiceForm = () => {
  return (
    <div className="service__contact three p_relative">
      <div className="auto-container">
        <div className="service__contact__block">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="service__form__data">
                <div className="form__title">
                  <div className="title__data">
                    <div className="sub__title">
                      <h4>Contact Us</h4>
                    </div>
                    <div className="title">
                      <h2>Send message</h2>
                    </div>
                  </div>
                </div>
                <div className="form-inner">
                  <ContactForm />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 p_relative">
              <div className="service__contact__img">
                <figure>
                  <img src={two} alt=" /" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceForm;
